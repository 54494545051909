<template>
  <div class="reset-password-container">
    <BaseNotification
      v-for="(notification, index) in notifications"
      :key="index"
      :notification="notification"
    />
    <div class="reset-password-body">
      <h1>Stel wachtwoord in</h1>
      <p class="description">
        Stel je nieuw wachtwoord in voor het e-mailadres
        <b>{{ email }}</b>
      </p>
      <form>
        <div v-for="input in inputs" :key="input.index">
          <BaseInput
            :placeholder="input.placeholder"
            :name="input.name"
            :id="input.id"
            :type="input.type"
            :value="input.value"
            :index="input.index"
            :message="input.message"
            @updatedValue="updateValue"
          />
        </div>
      </form>
      <BaseButton
        :disabled="
          allFilledIn === false ||
          passwordsMatch === false ||
          pin6Didgits === false
        "
        @buttonPressed="
          resetPasswordByPin({
            email: email,
            newPassword: inputs[0].value,
            pin: inputs[2].value,
          })
        "
        >Verzend</BaseButton
      >
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput";
import BaseNotification from "@/components/BaseNotification";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ForgotPassword",
  components: {
    BaseButton,
    BaseInput,
    BaseNotification,
  },
  data() {
    return {
      inputs: [
        {
          placeholder: "wachtwoord",
          name: "password1",
          id: "password1",
          type: "password",
          value: "",
          message: null,
        },
        {
          placeholder: "herhaal wachtwoord",
          name: "password2",
          id: "password2",
          type: "password",
          value: "",
          message: null,
        },
        {
          placeholder: "pin code bv. (361839)",
          name: "pin",
          id: "pin",
          type: "text",
          value: "",
          message: null,
        },
      ],
      email: this.$route.params.email,
    };
  },
  computed: {
    ...mapGetters({
      notifications: "notification/notifications",
    }),
    allFilledIn() {
      let foundEmpty = false;
      this.inputs.forEach((input) => {
        if (input.value === "") {
          foundEmpty = true;
        }
      });
      return !foundEmpty;
    },
    passwordsMatch() {
      return this.inputs[0].value === this.inputs[1].value ? true : false;
    },
    pin6Didgits() {
      return this.inputs[2].value.length === 6 ? true : false;
    },
  },
  methods: {
    ...mapActions({
      resetPasswordByPin: "auth/resetPasswordByPin",
    }),
    updateValue(params) {
      this.inputs.forEach((input) => {
        if (input.id === params.id) {
          input.value = params.value;
        }
      });
      this.validateInputs();
    },
    validateInputs() {
      if (this.inputs[0].value === "") {
        this.inputs[0].message = { text: "Verplicht veld", type: "error" };
      } else if (!this.passwordsMatch) {
        this.inputs[0].message = {
          text: "Wachtwoorden komen niet overeen",
          type: "error",
        };
      } else {
        this.inputs[0].message = null;
      }

      if (this.inputs[1].value === "") {
        this.inputs[1].message = { text: "Verplicht veld", type: "error" };
      } else if (!this.passwordsMatch) {
        this.inputs[1].message = {
          text: "Wachtwoorden komen niet overeen",
          type: "error",
        };
      } else {
        this.inputs[1].message = null;
      }

      if (this.inputs[2].value === "") {
        this.inputs[2].message = { text: "Verplicht veld", type: "error" };
      } else if (this.inputs[2].value.length < 6) {
        this.inputs[2].message = {
          text: "Pin is korter dan 6 karakters",
          type: "error",
        };
      } else if (this.inputs[2].value.length > 6) {
        this.inputs[2].message = {
          text: "Pin is langer dan 6 karakters",
          type: "error",
        };
      } else {
        this.inputs[2].message = null;
      }
    },
  },
  mounted() {
    this.validateInputs();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.reset-password-container {
  padding-top: 10vh;
  margin-left: 20vw;
  margin-right: 20vw;
  .reset-password-body {
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    h1 {
      text-align: center;
      margin-bottom: calc(#{$standard-margin}* 2);
    }
    .description {
      margin-bottom: $standard-margin;
      text-align: center;
    }
    form > div {
      margin-bottom: $standard-margin;
    }

    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
